import { render, staticRenderFns } from "./HelpAction.vue?vue&type=template&id=09569160&scoped=true&"
import script from "./HelpAction.vue?vue&type=script&lang=ts&"
export * from "./HelpAction.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09569160",
  null
  
)

export default component.exports