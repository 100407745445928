







import { Vue, Component, Emit } from 'vue-property-decorator'

@Component({
  name: 'HelpAction'
})
export default class HelpAction extends Vue {
  @Emit('click')
  onHelpClick() {
    // console.log('HelpAction onHelpClick')
  }
}
