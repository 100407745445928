









import { Component, Vue, Ref } from 'vue-property-decorator'
import LangSelect from '@/components/lang-select'
import HelpAction from './help-action'
import UserAccount from './user-account'
import ChangePassword from './Change-Password'

import { SiteStateModule } from '@/store/site_state'

@Component({
  name: 'HeaderBarActionPanel',
  components: {
    LangSelect,
    HelpAction,
    UserAccount,
    ChangePassword
  }
})
export default class HeaderBarActionPanel extends Vue {
  get lang() {
    return SiteStateModule.lang
  }
  set lang(l: string) {
    SiteStateModule.updateLang(l)
  }

  @Ref() readonly pwd!: ChangePassword

  async onHelpClick() {
    const siteName = SiteStateModule.site
    const helpManualGetUrl =
      '/api/hyper_fast/sys/config/find/sys.config.code.platform.' +
      siteName +
      '.userManual'
    const { data } = await this.$http.get(helpManualGetUrl)
    const helpManualUrl = data.data.data
    console.log('onHelpClick', data, helpManualUrl)
    window.open(helpManualUrl)
  }
  ChangePassword() {
    this.pwd.modifyPwd()
  }
}
